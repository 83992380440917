<template>
    <default-layout>
        <div class="p-3 job-overview__controls">
            <h4 class="text-center">Show jobs created by</h4>
            <div class="d-flex justify-content-center">
                <el-radio v-model="display" label="all">All</el-radio>
                <el-radio v-model="display" label="system">System</el-radio>
                <el-radio v-model="display" label="user">User</el-radio>
            </div>
            <div class="mt-3 row justify-content-center gx-2 gy-2">
                <div class="col-6 col-sm-4">
                    <el-date-picker
                        class="w-100"
                        v-model="dateFrom"
                        type="date"
                        placeholder="From"
                        format="DD-MM-YYYY"
                        :disabled-date="dateIsDisabled">
                    </el-date-picker>
                </div>
                <div class="col-6 col-sm-4">
                    <el-date-picker
                        class="w-100"
                        v-model="dateUntil"
                        type="date"
                        placeholder="Until"
                        format="DD-MM-YYYY"
                        :disabled-date="dateIsDisabled">
                    </el-date-picker>
                </div>
                <div class="d-none d-sm-block col-sm-2 d-flex justify-content-center">
                    <el-button type="primary" icon="el-icon-refresh" circle plain @click="clearDates" />
                </div>
                <div class="d-sm-none col-12 d-flex justify-content-center">
                    <el-button type="primary" icon="el-icon-refresh" plain @click="clearDates">Clear</el-button>
                </div>
            </div>
        </div>
        <div class="job-overview">
            <job-accordion :jobs="jobs" mode="unit" />
        </div>
    </default-layout>
</template>

<script lang="ts">
import _ from "lodash";
import {Options} from "vue-class-component";
import {buildingContext, jobContext} from "@/store";
import Vue from "@/extensions/Vue";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import JobData from "@/http/data/job-data";
import JobAccordion from "@/components/controls/JobAccordion.vue";
import dayjs from "dayjs";
import {JobOverviewDisplayValue} from "@/types/strings";

@Options({
    name: "Jobs",
    components: {
        JobAccordion,
        DefaultLayout
    },
})
export default class Jobs extends Vue {
    get dateFrom(): null|string {
        return jobContext.state.jobOverviewDateFrom;
    }
    set dateFrom(value: null|string) {
        jobContext.actions.selectJobOverviewDateFrom(value);
    }

    get dateUntil(): null|string {
        return jobContext.state.jobOverviewDateUntil;
    }
    set dateUntil(value: null|string) {
        jobContext.actions.selectJobOverviewDateUntil(value);
    }

    get display(): JobOverviewDisplayValue {
        return jobContext.state.jobOverviewDisplay;
    }
    set display(value: JobOverviewDisplayValue) {
        jobContext.actions.selectJobOverviewDisplay(value);
    }

    get jobs(): JobData[] {
        let jobChain = _.chain(jobContext.state.jobs)
            .filter((j: JobData) =>
                j.building_id === buildingContext.state.selectedBuildingId
                && j.shouldIndex());

        if (this.display === "system") {
            jobChain = jobChain.filter((job: JobData) => this.isSystemJob(job));
        } else if (this.display === "user") {
            jobChain = jobChain.filter((job: JobData) => !this.isSystemJob(job));
        }

        if (this.dateFrom) {
            jobChain = jobChain.filter((job: JobData) => dayjs(job.perform_date).isSameOrAfter(this.dateFrom, "day"));
        }

        if (this.dateUntil) {
            jobChain = jobChain.filter((job: JobData) => dayjs(job.perform_date).isSameOrBefore(this.dateUntil, "day"));
        }

        return jobChain
            .orderBy((j: JobData) => j.perform_date)
            .value();
    }

    get performDates(): string[] {
        return _.chain(jobContext.state.jobs)
            .filter((job: JobData) => !!job.perform_date)
            .map((job: JobData) => job.perform_date)
            .value();
    }

    get minPerformDate(): string {
        return _.min(this.performDates) as string;
    }

    get maxPerformDate(): string {
        return _.max(this.performDates) as string;
    }

    clearDates(): void {
        this.dateFrom = null;
        this.dateUntil = null;
    }

    dateIsDisabled(time: Date): boolean {
        return dayjs(time).isBefore(this.minPerformDate, "day")
            || dayjs(time).isAfter(this.maxPerformDate, "day");
    }

    isSystemJob(job: JobData): boolean {
        return job.created_by === "system"
            || job.created_by === "action"
            || _.includes([
                "SIGNED KEY-PICKUP DOCUMENT",
                "EARLY CHECK-IN",
                "LATE CHECKOUT 13H",
                "Afvalcontainer buiten zetten",
                "Glascontainer buiten zetten",
                "PMD zakken buiten zetten",
            ], job.description);
    }
}
</script>

<style scoped lang="scss">
@import "src/scss/variables";

.job-overview__controls {}
</style>